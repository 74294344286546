// https://material.angular.io/guide/system-variables

@use "@angular/material" as mat;
@use "./m3-dark-theme.scss" as dark;
@use "./m3-light-theme.scss" as light;

@use "./skeleton.scss";
@use "./ckeditor-theme.scss";

@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body {
  height: 100%;
  background: var(--mat-sys-surface);
  color: var(--mat-sys-on-surface);
}

body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

html {
  @media (prefers-color-scheme: light) {
    color-scheme: light;
    @include mat.theme(
      (
        color: (
          theme-type: light,
          primary: light.$palette,
        ),
        typography: (
          plain-family: "Roboto",
          brand-family: "Poppins",
          bold-weight: 900,
        ),
      )
    );
  }

  @media (prefers-color-scheme: dark) {
    color-scheme: dark;
    @include mat.theme(
      (
        color: (
          theme-type: dark,
          primary: dark.$palette,
        ),
        typography: (
          plain-family: "Roboto",
          brand-family: "Poppins",
          bold-weight: 900,
        ),
      )
    );
  }
}

:root {
  --skeleton-background-color: rgb(from var(--mat-sys-on-surface) r g b / 0.1);

  @include mat.toolbar-overrides(
    (
      container-background-color: rgb(from var(--mat-sys-on-surface) r g b / 0),
    )
  );

  @include mat.divider-overrides(
    (
      color: rgb(from var(--mat-sys-outline) r g b / 0.2),
    )
  );
}

// Fix for tailwind introducing bug to outline mat-input
.mdc-notched-outline__notch {
  border-right: none;
}

.mat-sidenav-content {
  scrollbar-gutter: stable;
}

// Custom scrollbar
/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background-color: var(--mat-sys-surface);
}

/* Handle */
::-webkit-scrollbar-thumb {
  background-color: rgb(from var(--mat-sys-inverse-surface) r g b / 0.1);
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background-color: rgb(from var(--mat-sys-inverse-surface) r g b / 0.2);
}

.mat-mdc-card[appearance="raised"] {
  background-color: var(--mat-sys-surface-dim);
  box-shadow: var(--mat-sys-level0);
}

a,
a:visited {
  color: var(--mat-sys-primary);
  text-decoration: none;

  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
}

.mat-sys-title-large {
  font: var(--mat-sys-title-large);
}

.mat-sys-headline-large {
  font: var(--mat-sys-headline-large);
}

.mat-sys-headline-medium {
  font: var(--mat-sys-headline-medium);
}

.page-headline {
  //mat-sys-headline-medium font-medium  uppercase my-6
  font: var(--mat-sys-headline-small);
  font-weight: 500;
  text-transform: uppercase;
}
